<template>
  <TTView>
    <VRow>
      <VCol>
        <h2>{{ $t('content-provider.edit_title') }}</h2>
      </VCol>
    </VRow>
    <ContentProviderForm
      :provider="provider"
      :is-visible-cancel-button="true"
      :submit-button-text="$t('content-provider.edit')"
      @submit="submit"
      @cancel="handleCancel"
    />
  </TTView>
</template>

<script>
import ContentProviderForm from '../../components/content-provider/ContentProviderForm.vue';

export default {
  name: 'ContentProviderEdit',
  components: {
    ContentProviderForm,
  },
  inject: ['Names'],
  data() {
    return {
      loading: false,
      isValid: false,
      photo: null,
      provider: {
        title: '',
        siteLink: '',
        description: '',
        logo: '',
      },
    };
  },

  computed: {
    providerId() {
      return this.$route.params.id;
    },
  },
  async created() {
    await this.fetchProvider();
  },

  methods: {
    async fetchProvider() {
      try {
        this.loading = true;

        const { provider } = await this.$di.api.Catalogue.providerGet({
          id: this.providerId,
        });

        this.provider = provider;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async updateProvider(provider) {
      try {
        this.loading = true;

        await this.$di.api.Catalogue.providerUpdate({
          id: this.providerId,
          provider,
        });

        // Загрузим картинку только если пользователь добавил новую
        if (provider.logo) {
          await this.uploadProviderLogo({ id: this.providerId }, provider.logo);
        }

        this.$di.notify.snackSuccess('Провайдер обновлён');
        await this.handleCancel();
        return true;
      } catch (error) {
        this.$di.notify.errorHandler(error);
        return false;
      } finally {
        this.loading = false;
      }
    },

    async uploadProviderLogo(provider, image) {
      try {
        this.loading = true;

        const data = new FormData();
        data.append('image', image);

        return this.$di.api.Gogha.UploadImage('provider', provider.id, 'icon', data);
      } catch (error) {
        this.$di.notify.errorHandler(error);
        return null;
      } finally {
        this.loading = false;
      }
    },

    async submit(isValid, provider) {
      if (isValid) {
        await this.updateProvider(provider);
      }
    },

    async handleCancel() {
      return this.$router.push({ name: this.Names.R_LEARNING_CONTENT_PROVIDER });
    },
  },
};
</script>
